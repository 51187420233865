/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { ForbrukertestThemeSaver } from './src/components/ForbrukertestThemeSaver';
import React from 'react';

// You can delete this file if you're not using it
export const wrapPageElement = ({ element, props }) => (
  <ForbrukertestThemeSaver {...props}>{element}</ForbrukertestThemeSaver>
);

export const onRouteUpdate = () => {
  // console.log('$pageview ass', arguments);
  if (window.posthog) {
    window.posthog.capture('$pageview');
  }
};
