import { ThemeProvider } from '@mui/system';
import React, { useContext } from 'react';
import { useState } from 'react';
import { forbrukertestThemeDark, forbrukertestThemeLight } from '../theme';

export const ForbrukertestThemeSaver = ({ children }) => {
  const localTheme = localStorage.getItem('gt_theme');
  const [theme, setTheme] = useState(
    localTheme === 'dark' ? forbrukertestThemeDark : forbrukertestThemeLight,
  );

  const changeTheme = () => {
    const newTheme =
      theme.palette.mode === 'light'
        ? forbrukertestThemeDark
        : forbrukertestThemeLight;
    setTheme(newTheme);
    localStorage.setItem('gt_theme', newTheme.palette.mode);
  };
  return (
    <ThemeContext.Provider
      value={{ changeTheme: changeTheme, theme: theme.palette.mode }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ThemeContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeTheme: (): void => {},
  theme: '',
});

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
